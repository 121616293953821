<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-card-text class="pt-4">
        <v-row no-gutters>
          <v-col cols="12" md="4" class="pr-1">
            <texto2 :colores="colores" :titulo="'CÓDIGO REQUERIMIENTO'" :descripcion="registro.codigo"></texto2>
          </v-col>
          <v-col cols="12" md="4" class="pr-1">
            <texto2  v-if="registro.fecha_requerimiento" :colores="colores" :titulo="'FECHA RADICACIÓN'" :descripcion="registro.fecha_requerimiento.substr(0, 10)"></texto2>
          </v-col>
          <v-col cols="12" md="4">
            <texto2  v-if="registro.fechaparacerrarexpres !== null || registro.fechaparacerrarinterno !== null" :colores="colores" :titulo="'FECHA RESPUESTA'" :descripcion="(registro.quejaexpres_id===29)?registro.fechaparacerrarexpres.substr(0, 10):registro.fechaparacerrarinterno.substr(0, 10)"></texto2>
          </v-col>
        </v-row>
        <div v-if="dataReplica.length > 0">
          <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>RÉPLICA</b></span></v-toolbar>
          <v-row no-gutters v-for="(itemReplica, indexEsc) in dataReplica" :key="indexEsc">
            <v-col cols="12" md="4" class="pr-1">
              <texto2 :colores="colores" :titulo="'CÓDIGO REQUERIMIENTO'" :descripcion="registro.codigo"></texto2>
            </v-col>
            <v-col cols="12" md="4" class="pr-1">
              <texto2  :colores="colores" :titulo="'FECHA RADICACIÓN'" :descripcion="itemReplica.created_at.substr(0, 10)"></texto2>
            </v-col>
            <v-col cols="12" md="4">
              <texto2  :colores="colores" :titulo="'FECHA RESPUESTA'" :descripcion="(itemReplica.fechaparacerrarprorroga !== null )? itemReplica.fechaparacerrarprorroga.substr(0, 10) : itemReplica.fechaparacerrarinterno.substr(0, 10)"></texto2>
            </v-col>
          </v-row>
        </div>
        <v-row no-gutters>
          <v-col cols="12">
            <v-card :color="registro.subestado.color" dark class="pa-1 text-h6 text-center">{{ registro.subestado.descripcion }}</v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-sheet>
</div>
</template>

<script>
// import texto2 from '@/components/widgets/text1Component.vue'
export default {
  name: 'crm_lectura_solicitanteComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue')
  },
  props: ['colores', 'registro', 'dataReplica'],
  data: () => ({
  }),
  created () {
  },
  methods: {
  }

}
</script>
